import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import React from 'react';
import cx from 'classnames';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import { CustomPlaceholder, UploadedDocument } from '../../../types/UploadADoc';
import {
    setupCloneButton,
    addDropEventToWebviewerIframe,
    fetchUploadedDocumentFile,
    initialiseWebViewerInstance,
    loadSavedAnnotationString,
    maintainFreeTextAnnotationSize,
    addCustomPlaceholdersDrawMethod,
    updateSelectionBoxPadding,
    updateFreeTextSelectionBox,
    updateStampAnnotationSelectionBox,
    updateControlHandles,
    updateAnnotationsVisibility,
    fixAnnotationResizing
} from '../utils';
import { AnnotationAction, AnnotationChangedSources, Point, isCustomPlaceholderAnnotation } from '../types';
import useToast from '../../../hooks/useToast';
import { ONLY_ALLOW_FREE_TEXT_RESIZING, ALLOW_SENDER_PLACEHOLDER_RESIZING } from '../../../constants/featureFlags';

import styles from './WebViewerInstance.module.scss';

declare const PDF_TRON: string;

type WebViewerInstanceComponentProps = {
    isSelected: boolean;
    uploadedDocument: UploadedDocument;
    onWebViewerCreated: (uploadedDocumentId: string, instance: WebViewerInstance) => void;
    onUpdateClientAnnotationCounts: (
        uploadedDocumentId: string,
        annotations: Core.Annotations.Annotation[],
        action: AnnotationAction,
        isLoadingAnnotationString: boolean
    ) => void;
    isOnboarding?: boolean;
    onDrop: (point: Point) => void;
    customPlaceholders: CustomPlaceholder[];
    hideCustomPlaceholderTitles: boolean;
    hoveredCustomPlaceholderId?: string;
    numberOfFormClients: number;
    onWebViewerInitialised: (uploadedDocumentId: string) => void;
    documentId: string;
    isTemplate: boolean;
    isEoc?: boolean;
};

function WebViewerInstanceComponent({
    isSelected,
    uploadedDocument,
    onWebViewerCreated,
    onUpdateClientAnnotationCounts,
    isOnboarding,
    onDrop,
    customPlaceholders,
    hideCustomPlaceholderTitles,
    hoveredCustomPlaceholderId,
    numberOfFormClients,
    onWebViewerInitialised,
    documentId,
    isTemplate,
    isEoc
}: WebViewerInstanceComponentProps): React.ReactElement {
    const { addNewToast } = useToast();
    const viewerRef = React.useRef<HTMLDivElement | null>(null);
    const instanceRef = React.useRef<WebViewerInstance | null>(null);
    const isOnlyAllowFreeTextResizingActive = useFeatureFlag(ONLY_ALLOW_FREE_TEXT_RESIZING);
    const isAllowSenderPlaceholderResizingActive = useFeatureFlag(ALLOW_SENDER_PLACEHOLDER_RESIZING);
    const [isInitialisingWebViewer, setIsInitialisingWebViewer] = React.useState(false);

    const [hasLoadedDocument, setHasLoadedDocument] = React.useState(false);
    const [hasLoadedAnnotationString, setHasLoadedAnnotationString] = React.useState(false);
    // TODO (VA): Display a confirmAlert to the user when they open a file with an error
    const [hasError, setHasError] = React.useState(false);

    // Initialise webviewer
    React.useEffect(() => {
        if (viewerRef.current && !instanceRef.current && !isInitialisingWebViewer) {
            setIsInitialisingWebViewer(true);
            WebViewer(
                { licenseKey: PDF_TRON, path: '/23862', css: '/23862/pdf-viewer.css', fullAPI: true },
                viewerRef.current
            )
                .then(instance => {
                    setIsInitialisingWebViewer(false);
                    instanceRef.current = instance;

                    onWebViewerCreated(uploadedDocument.id, instance);
                    initialiseWebViewerInstance(instance);
                    addDropEventToWebviewerIframe(instance, onDrop);
                    setupCloneButton(instance, addNewToast);

                    updateSelectionBoxPadding(instance);
                    updateStampAnnotationSelectionBox(instance);
                    updateControlHandles(instance);
                    fixAnnotationResizing(instance);

                    instance.Core.documentViewer.addEventListener('documentLoaded', async () => {
                        setHasLoadedDocument(true);
                    });

                    instance.Core.documentViewer.addEventListener('annotationsLoaded', async () => {
                        const annotations = await instance.Core.annotationManager.getAnnotationsList();

                        // Remove any annotations that failed to flatten (this can happen with Link annotations)
                        // This fix has temporarily been disabled because it was causing a bug (DEV-3681)
                        // removeExternalAnnotations(instance);

                        updateFreeTextSelectionBox(
                            instance,
                            annotations.filter(
                                annotation => annotation instanceof instance.Core.Annotations.FreeTextAnnotation
                            ) as Core.Annotations.FreeTextAnnotation[],
                            {
                                onlyAllowFreeTextResizing: isOnlyAllowFreeTextResizingActive,
                                allowSenderPlaceholderResizing: isEoc || isAllowSenderPlaceholderResizingActive
                            }
                        );
                    });

                    fetchUploadedDocumentFile(
                        documentId,
                        uploadedDocument.document.file,
                        blob => instance.UI.loadDocument(blob, { extension: 'pdf' }),
                        () => setHasError(true),
                        isOnboarding
                    );
                })

                .catch(error => {
                    // TODO (VA): Display a confirmAlert to the user when they open a file with an error
                    setHasError(true);
                    setIsInitialisingWebViewer(false);
                    window.rollbar.error(
                        'Unexpected error',
                        {
                            error_message: error.message,
                            status: 'error'
                        },
                        error
                    );
                });
        }
    }, [
        addNewToast,
        documentId,
        isOnboarding,
        onDrop,
        onWebViewerCreated,
        uploadedDocument.document.file,
        uploadedDocument.id,
        isOnlyAllowFreeTextResizingActive,
        isInitialisingWebViewer,
        isAllowSenderPlaceholderResizingActive,
        isEoc
    ]);

    React.useEffect(() => {
        if (instanceRef.current && hasLoadedAnnotationString) {
            const instance = instanceRef.current;

            const annotationChangedHandler = (
                annotations: Core.Annotations.Annotation[],
                action: AnnotationAction,
                info: { source: AnnotationChangedSources }
            ) => {
                if (action === AnnotationAction.MODIFY) {
                    if (info?.source === AnnotationChangedSources.DRAGGING_ACROSS_PAGES) {
                        annotations.forEach(annotation => {
                            if (annotation instanceof instance.Core.Annotations.FreeTextAnnotation) {
                                maintainFreeTextAnnotationSize(annotation);
                            }
                        });
                    }
                } else if (action === AnnotationAction.ADD || action === AnnotationAction.DELETE) {
                    onUpdateClientAnnotationCounts(uploadedDocument.id, annotations, action, false);
                }

                if (action === AnnotationAction.ADD || action === AnnotationAction.MODIFY) {
                    updateFreeTextSelectionBox(
                        instance,
                        annotations.filter(
                            annotation => annotation instanceof instance.Core.Annotations.FreeTextAnnotation
                        ) as Core.Annotations.FreeTextAnnotation[],
                        {
                            onlyAllowFreeTextResizing: isOnlyAllowFreeTextResizingActive,
                            allowSenderPlaceholderResizing: isEoc || isAllowSenderPlaceholderResizingActive
                        }
                    );
                }
            };

            instance.Core.annotationManager.addEventListener('annotationChanged', annotationChangedHandler);

            return () => {
                instance.Core.annotationManager.removeEventListener('annotationChanged', annotationChangedHandler);
            };
        }
    }, [
        hasLoadedAnnotationString,
        onUpdateClientAnnotationCounts,
        uploadedDocument.id,
        isOnlyAllowFreeTextResizingActive,
        isEoc,
        isAllowSenderPlaceholderResizingActive
    ]);

    // Update custom placeholder UI in response to changes
    React.useEffect(() => {
        if (hasLoadedDocument && instanceRef.current) {
            if (customPlaceholders) {
                addCustomPlaceholdersDrawMethod(instanceRef.current, customPlaceholders, {
                    hideTitles: hideCustomPlaceholderTitles,
                    highlightedPlaceholderId: hoveredCustomPlaceholderId
                });

                const { annotationManager } = instanceRef.current.Core;
                annotationManager
                    .getAnnotationsList()
                    .filter(isCustomPlaceholderAnnotation)
                    .forEach(annotation => annotationManager.redrawAnnotation(annotation));
            }
        }
    }, [customPlaceholders, hideCustomPlaceholderTitles, hoveredCustomPlaceholderId, hasLoadedDocument]);

    React.useEffect(() => {
        const loadAnnotations = async () => {
            if (hasLoadedDocument && !hasLoadedAnnotationString && instanceRef.current) {
                const annotationsFromString = await loadSavedAnnotationString(
                    instanceRef.current,
                    uploadedDocument.annotations || ''
                );
                setHasLoadedAnnotationString(true);

                onUpdateClientAnnotationCounts(uploadedDocument.id, annotationsFromString, AnnotationAction.ADD, true);
                onWebViewerInitialised(uploadedDocument.id);
            }
        };

        loadAnnotations();
    }, [
        hasLoadedDocument,
        uploadedDocument.annotations,
        uploadedDocument.id,
        onUpdateClientAnnotationCounts,
        hasLoadedAnnotationString,
        onWebViewerInitialised
    ]);

    React.useEffect(() => {
        if (hasLoadedAnnotationString && instanceRef.current) {
            updateAnnotationsVisibility(instanceRef.current, isTemplate, numberOfFormClients);
        }
    }, [numberOfFormClients, hasLoadedAnnotationString, isTemplate]);

    return (
        <div
            className={cx(styles.webViewerInstance, { [styles.selected]: isSelected })}
            ref={viewerRef}
            data-test={`webviewer-instance-${uploadedDocument.id}`}
        />
    );
}

const WebViewerInstanceMemo = React.memo(WebViewerInstanceComponent);

export default WebViewerInstanceMemo;
